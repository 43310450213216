import module from 'module';
import logger from 'OK/logger';
import {unescapeXml, ajax, encodeHtml } from 'OK/utils/vanilla';
import { addPackagePromise, getPackageResolve, getPackagePromise, hasPackagePromise, deallocatePackage } from 'OK/PackageRegistry';

/**
* 'OK/pts!your.package'
* Добавить PTS пакет 'your.package' в odnoklassniki-web / app.feature.pts
*/

var
    /** @type {{}} */
    resources = {},
    /** @type {string[]} */
    toFetch = [],
    /** @type {{}} */
    events = {},
    /** @type {?number} */
    interval,
    /** @type {string} */
    HASH = '$Hash$',
    /** @type {number} */
    TIMEOUT = module.config().timeout || 300000 /* 5 минут */
    ;

/* Нотифицируем заинтересованных об обновлениях */
function notify(eventName) {
    var
        /** @type {function()[]} */
        subscribers = events[eventName] || [],
        /** @type {number} */
        len = subscribers.length;
    while (len--) {
        subscribers[len]();
    }
}

function hasPkg(name) {
    return resources.hasOwnProperty(name);
}

function getPkg(name) {
    var pkg = resources[name];
    if ((!pkg || Object.keys(pkg).length === 0) && OK.fn.isDebug()) {
        console.error('Пакет "'+ name + '" отсутствует. Наверно, не удалось загрузить');
    }
    return resources[name];
}

function registerPkg(name, messages) {
    resources[name] = new MessageResource(name, messages);
    notify(name);
    return getPkg(name);
}

class MessageResource {
    constructor(pkg, messages) {
        this.pkg = pkg;
        this.messages = messages;
        this.parsedResources = {};
    }

    /**
     * @param {string} key
     * @returns {boolean}
     */
    has(key) {
        return this.messages.hasOwnProperty(key);
    }

    /**
     * @param {string} key
     * @param {?{}} args
     * @returns {string}
     */
    getLMsg(key, args) {

        if (!key && !args) {
            return this.messages;
        }

        var value = this.messages && this.messages[key];
        if (typeof value !== 'string') {
            logger.success('pts.miss', this.pkg, key);
            return this.pkg + "." + key;
        }

        if (args) {

            var parsedResource = this.parsedResources[key];
            if (!parsedResource) {
                parsedResource = parseResource(value);
                this.parsedResources[key] = parsedResource;
            }

            return parsedResource.getResource(args);
        }

        return value;
    }

    /**
     * @param {function()} callback
     */
    subscribe(callback) {
        var
            /** @type {string} */
            eventName = this.pkg;
        if (!events[eventName]) {
            events[eventName] = [];
        }
        events[eventName].push(callback);
    }
}

//see https://wiki.odkl.ru/pages/viewpage.action?pageId=9928966

class TextNode {
    constructor(text) {
        this.text = text;
    }

    getResource() {
        // если в тексте встречались теги, то encodeHtml переведет их в специфические символы xml
        // возвращаем их в прежнее состояние
        return unescapeXml(encodeHtml(this.text));
    }
}

class NamedNode {
    constructor(argName) {
        this.argName = argName;
    }

    getResource(args) {
        var res = args[this.argName];
        // отдельно обрабатываем 0, чтобы он показывался и не заменялся на пустую строку
        return res || res === 0 ? res : '';

    }
}

class IndexedSwitchNode {
    constructor(argName, values) {
        this.argName = argName;
        this.values = values;
    }

    getResource(args) {
        var arg = args[this.argName], index = arg, plural = this.argName.substr(-"Plural".length) === "Plural";

        if (plural) {
            index = pluralAlgo.getIndex(arg);
        }

        var child = this.values[index];

        if (child) {
            return child.getResource(args);
        }

        return '';
    }
}

class KeyedSwitchNode {
    constructor(argName, values) {
        this.argName = argName;
        this.values = values;
    }

    getResource(args) {
        var arg = args[this.argName], value = this.values[arg];

        return value ? value.getResource(args) : '';
    }
}

function pEAST_SLAVIC_ALGORITHM(value){
    value = Math.abs(value);

    if (value % 10 === 1 && value % 100 !== 11) {
        return 0;
    }
    if ((value % 10 >= 2 && value % 10 <= 4) && (value % 100 < 10 || value % 100 >= 20)) {
        return 1;
    }
    return 2;
}

function pROMANO_GERMANIC_ALGORITHM(value){
    return (value === 1) ? 0 : 1;
}

function pPLURAL0(value){
    return 0;
}

// see one.comp.l10n.format.plural.PluralAlgorithmRegistry
var pluralAlgo = {
    "ru": pEAST_SLAVIC_ALGORITHM,
    "uk": pEAST_SLAVIC_ALGORITHM,

    "en": pROMANO_GERMANIC_ALGORITHM,
    "hy": pROMANO_GERMANIC_ALGORITHM,
    "mo": pROMANO_GERMANIC_ALGORITHM,
    "ro": pROMANO_GERMANIC_ALGORITHM,
    "de": pROMANO_GERMANIC_ALGORITHM,

    "uz": pPLURAL0,
    "az": pPLURAL0,
    "kk": pPLURAL0,
    "ka": pPLURAL0,
    "ky": pPLURAL0,
    "tg": pPLURAL0,
    "tr": pPLURAL0,

    getIndex : function(number) {
        var locale = document.body.parentElement.getAttribute("lang");
        var alg = pluralAlgo[locale];
        if (!alg) {
            alg = pEAST_SLAVIC_ALGORITHM;
        }
        return alg(number);
    }
};

class ListNode {
    constructor(children) {
        this.children = children;
    }

    getResource(args) {
        var result = "";
        for (var i = 0; i < this.children.length; i++) {
            result += this.children[i].getResource(args);
        }
        return result;
    }
}


function parseResource(text) {
    var nodes = [];
    var start = 0;
    for(;;) {
        var i = nextNode(nodes, text, start);
        if (i === text.length) {
            break;
        }

        if (start === i) {
            i++; // какая-то борода, н-р, | до первого [
        }

        start = i;
    }

    return createNode(nodes);
}

function nextNode(nodes, text, start) {

    var i;

    i = readUntil(text, start, '|[]');

    if (i === -1) {
        i = readUntil(text, start, ']'); //switch'eй нет совсем или какой-то свитч поломан.
        if (i === -1) {
            nodes.push(new TextNode(text.substr(start)));
            return text.length;
        }
        nodes.push(new TextNode(text.substr(start, i - start)));
        return i; // ->  | or ]
    }


    if (i > start) {
        //нашли кусок текста
        nodes.push(new TextNode(text.substr(start, i - start)));
        return i; // -> |, [ or ]
    }

    if (text[i] === '[') {
        start = i + 1;
        i = readUntil(text, start, ':?]');

        if (i === -1) {
            start--;
            nodes.push(new TextNode(text.substr(start)));
            return text.length;
        }

        var argName = trim(text.substr(start, i - start));

        if (text[i] === ']') {
            nodes.push(new NamedNode(argName));
            return i + 1;
        }

        var switchType = text[i],
            keyed = switchType === '?',
            values = keyed ? {} : [];

        if (switchType !== '?' && switchType !== ':') {
            i = readUntil(text, start, ']');
            nodes.push(new TextNode(text.substr(start - 1, i)));
            return text.length;
        }

        for(;;) {
            start = i + 1; //пропускаем switchType (на первом заходе) или | (на остальных)

            var argNodes = [],
                key;

            if (keyed) {
                i = readUntil(text, start, '=');

                if (i > start) {
                    key = text.substr(start, i - start);
                    start = i + 1;
                } else {
                    i = readUntil(text, start, ']');
                    nodes.push(new TextNode(text.substr(start, i)));
                    return text.length;
                }
            }

            //будем читать ноды аргумента пока не упрёмся в токен разделитель или конец switch'a
            for(;;) {
                i = nextNode(argNodes, text, start);

                if (i === text.length) {
                    // всё дочитали!
                    break;
                }

                if (text[i] === '|' || text[i] === ']') {
                    // дочитали до конца аргумента или конца свитча.
                    break;
                }

                if (start == i) {
                    i++; //чтобы случайно не зациклиться...
                }

                start = i;
            }

            //перекидываем прочитанные ноды в switch
            if (keyed) {
                values[key] = createNode(argNodes);
            } else {
                values.push(createNode(argNodes));
            }

            //
            if (i === text.length || text[i] === ']') {
                break; // дочитали все аргументы или какая-то ошибка в ресурсе.
            }
        }
        // i -> ] or text.length

        if (keyed) {
            nodes.push(new KeyedSwitchNode(argName, values));
        } else {
            nodes.push(new IndexedSwitchNode(argName, values));
        }

        if (i === text.length) {
            return text.length;
        }

        return i + 1; // прыгаем за закрывающую скобку.
    }

    return i;
}

function trim(v) {
    return v ? v.replace(/^\s+|\s+$/gm, '') : '';
}

function createNode(nodes) {
    if (nodes.length === 0) {
        return new TextNode('');
    }

    if (nodes.length === 1) {
        return nodes[0];
    }

    return new ListNode(nodes);
}

function readUntil(text, start, oneOfChar) {
    for (var i = start; i < text.length; i++) {
        for (var j = 0; j < oneOfChar.length; j++) {
            if (oneOfChar[j] === text[i]) {
                return i;
            }
        }
    }
    return -1;
}

/**
 * @see one.app.community.dk.blocks.pts.PtsEndpoint
 * @typedef {Object} PkgInfo
 * @property {string} pkg
 * @property {string} hash
 */

/**
 * Данные запроса обновления локализации в виде сериализованного JSON
 * @returns {string}
 */
function makeData() {
    var
        /** @type {PkgInfo[]} */
        info = [],
        /** @type {string} */
        pkg,
        /** @type {number} */
        idx = 0,
        /** @type {number} */
        len = toFetch.length;
    for (; idx < len; idx += 1) {
        pkg = toFetch[idx];
        var pkgObj = getPkg(pkg);
        info.push({
            'pkg': pkg,
            'hash': pkgObj && pkgObj.messages[HASH] || '0'
        });
    }
    return JSON.stringify(info);
}

/* Опрашиваем сервер */
function refresh() {
    ajax({
        url: '/web-api/upts',
        headers: {
             'Content-Type': 'application/json'
        },
        data: makeData(),
        dataType: 'json'
    }).then(function (result) {
        var
            /** @type {[]} */
            response = result.response,
            /** @type {string[]} */
            packages = Object.keys(response),
            /** @type {string} */
            pkg,
            /** @type {number} */
            idx = 0,
            /** @type {number} */
            len = packages.length;
        for (; idx < len; idx += 1) {
            pkg = packages[idx];
            /* Обновим хранилище новыми данными */
            registerPkg(pkg, response[pkg]);
        }
    });
}

/**
 * @param {string} pkg
 */
function remember(pkg) {
    if (!pkg) {
        /* Ничего не делаем */
        return;
    }
    if (toFetch.indexOf(pkg) === -1) {
        /* Добавим пакет локализации к списку периодического опроса обновлений */
        toFetch.push(pkg);
    }
    if (!interval && (TIMEOUT > 0)) {
        /* Запустим периодический опрос */
        interval = window.setInterval(refresh, TIMEOUT);
    }
}


// Определяем глобальный стор текстов
if (typeof window !== 'undefined') {
    window.l10n = {
        /**
         * @param pkg
         * @returns {MessageResource}
         */
        getPkg: function (pkg) {
            remember(pkg);
            return getPkg(pkg);
        }
    };

    if (window.inline_resources !== undefined && window.inline_resources.pts !== undefined) {
    	for (var alias in window.inline_resources.pts){
    		registerPkg(alias, window.inline_resources.pts[alias])
    		remember(alias);
    	}
    	window.inline_resources.pts = {}
    };
}

function load(name, req, onload, config) {
    if (config.isBuild) {
        onload();
        return;
    }

    var packageNames = name.split(",");
    var unrequestedPackages = packageNames.filter(function(packageName) {
        return !hasPkg(packageName) && !hasPackagePromise(packageName);
    });
    unrequestedPackages.sort();
    unrequestedPackages.forEach(function(packageName) {
        addPackagePromise(packageName);
    });

    if (unrequestedPackages.length > 0) {
        req(["PTS/" + unrequestedPackages.join(",")], function(messages) {
            unrequestedPackages.forEach(function(packageName) {
                registerPkg(packageName, messages[packageName]);
                getPackageResolve(packageName)(getPkg(packageName));
                deallocatePackage(packageName);
            });
        });
    }

    Promise.all(packageNames.map(function(packageName) {
        if (hasPkg(packageName)) {
            return getPkg(packageName);
        }
        return getPackagePromise(packageName);
    })).then(function(packages) {
        // Чтобы поддержать существующий формат ответа при импорте одного пакета
        if (packages.length === 1) {
            packages = packages[0];
        }
        onload(packages)
    });
}

export default { load };

export { load };
